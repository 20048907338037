import { TreauAPI } from '../../api_clients/TreauApiClient';

import deviceImage from '../../images/productx3.png';
import {
  getRunStatusName,
  getRunStatusColor,
  getSystemStateName,
  getSystemStateColor,
  getPowerName,
  getModeName
} from '../../utils/utils';

import './index.scss';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';

interface DeviceGalleryProps {
  devices: TreauAPI.ResponsesAdminDeviceOverview[];
  loading?: boolean;
}

const DeviceGallery = (props: DeviceGalleryProps) => {

    const { devices } = props;

    const history = useHistory();

    const onDeviceClick = (device: TreauAPI.ResponsesAdminDeviceOverview) => {
        history.push('/dashboard/' + device.unid);
    }

    if (props.loading) {
      return (
        <div className='search-gallery-view d-flex justify-content-center align-items-center'>
          <LoadingSpinner/>
        </div>
      );
    } else if (devices && devices.length > 0) {
    return (
        <div className="search-gallery-view">
            {devices.map(device => {
                const shadowDoc = device.shadowDoc || {};
                const deviceInfo = shadowDoc['device_info'] || {};
                return (
                    <div key={device.unid} onClick={() => onDeviceClick(device)} className="gallery-view-item">
                        <div className="gallery-view-item-content">
                            <div className="device-image"><img src={deviceImage} alt="" /></div>
                            {getSystemStateName(shadowDoc['state_system']) && <div className="device-indoor-sn">
                                <div style={{ backgroundColor: getSystemStateColor(shadowDoc['state_system']) }} className="dv-mode-bg"></div>
                                <div className="value">{getSystemStateName(shadowDoc['state_system'])}</div>
                            </div>}
                            {getRunStatusName(shadowDoc['run_status']) && <div className="device-indoor-sn">
                                <div style={{ backgroundColor: getRunStatusColor(shadowDoc['run_status']) }} className="dv-mode-bg"></div>
                                <div className="value">{getRunStatusName(shadowDoc['run_status'])}</div>
                            </div>}
                            {getPowerName(shadowDoc['state_power'] || shadowDoc['power_state']) && <div className="device-mode">
                                <div className="dv-mode-name">Power: {getPowerName(shadowDoc['state_power'] || shadowDoc['power_state'])}</div>
                            </div>}
                            {getModeName(device.deviceType, shadowDoc['op_mode']) && <div className="device-mode">
                                <div className="dv-mode-name">Mode: {getModeName(device.deviceType, shadowDoc['op_mode'])}</div>
                            </div>}

                            <div className="device-info">
                                <div className="device-info-item">
                                    {"Idb Mac Id:"}<p>{(deviceInfo['idb_sn'] || deviceInfo['idb_id'] || deviceInfo['idb_mac_id']) ?? "Empty"}</p>
                                </div>
                                <div className="device-info-item">
                                    {"Primary Owner :"}<p>{device.primaryUserName ?? "Empty"}</p>
                                </div>
                                <div className="device-info-item">
                                    {"Email :"} <p>{device.primaryUserEmail ?? "Empty"}</p>
                                </div>
                                <div className="device-info-item">
                                    {"Thing Name :"}<p>{device.thingName}</p>
                                </div>
                                <div className="device-info-item">
                                    {"Last Shadow Timestamp :"}<p>{device.lastConnectedTime}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
      );
    } else {
      return (<div className="gallery-no-device">No Content</div>);
    }
}

export default DeviceGallery;
