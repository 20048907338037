import {useContext} from 'react';
import {AxiosResponse} from "axios";
import {isNullOrUndefined} from "../../utils/utils";
import {toast} from "react-toastify";
import { logout } from '../../contexts/LoginContext/actions';
import { LoginContext } from '../../contexts/LoginContext';

export const useToastAPIHandler = () =>{
    const loginContext = useContext(LoginContext);
    const handleRequest = <T extends unknown>(promise: Promise<AxiosResponse<T>>, onSuccess:(response:any)=>void, onError?:(error:any)=>void, onEnd?:()=>void):void=>{
        promise.then((response:AxiosResponse<T>)=> {
            onSuccess(response);
        }).catch((error:any)=> {
            if(!isNullOrUndefined(error.request) && !isNullOrUndefined(error.request.response)){
                let errorResponse;
                try{
                    errorResponse = JSON.parse(error.request.response);
                }catch (e) {
                    console.log(e)
                }
                if (errorResponse && errorResponse.code === 401) {
                  logout(loginContext.dispatch);
                } else if(errorResponse && !isNullOrUndefined(errorResponse.validationErrors)){
                    Object.values<string>(errorResponse.validationErrors).forEach((errorMessage)=>{
                        toast.error(errorMessage, {position: toast.POSITION.TOP_CENTER});
                    })
                }else if(errorResponse && errorResponse.message){
                    toast.error(errorResponse.message, {position: toast.POSITION.TOP_CENTER});
                }else{
                    toast.error("Unknown Error", {position: toast.POSITION.TOP_CENTER});
                }
                if(!isNullOrUndefined(onError)){
                    // @ts-ignore
                    onError(errorResponse) ;
                }
            }else{
                toast.error("Unknown Error", {position: toast.POSITION.TOP_CENTER});
                console.log(error);
                if(!isNullOrUndefined(onError)){
                    // @ts-ignore
                    onError(error) ;
                }
            }
        }).finally(()=>{
        if(!isNullOrUndefined(onEnd)){
            // @ts-ignore
            onEnd()
        }
        });
    };

    return{
        handleRequest
    };
};
