import React from 'react';
import Modal from 'react-bootstrap/Modal'
import LoadingSpinner from '../../LoadingSpinner';

interface ILoadingProps {
    isLoading: boolean;
}

const Loading = (props: ILoadingProps) => {
    return (
        <Modal
            show={props.isLoading}
            style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
               
            }}
            className='d-flex'
            size="sm"
        >
            <Modal.Body style={{ minWidth:'300px'}}>
              <LoadingSpinner />
            </Modal.Body>
        </Modal>
    )
}
export default Loading;

