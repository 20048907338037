import React, { useEffect, useRef, useState } from 'react';
import treauApiClient, { TreauAPI } from '../../../../api_clients/TreauApiClient';

import Button from '../../../../components/Button';
import LoadingSpinner from '../../../../components/LoadingSpinner';

import { Form } from 'react-bootstrap'

import { useToastAPIHandler } from '../../../../hooks/api';

import { AxiosResponse } from 'axios';

interface DataStreamingFormProps {
  unid: string;
}

const REFRESH_INTERVAL_SECONDS = 5;

const DataStreamingForm = (props: DataStreamingFormProps) => {
  const apiHandler = useToastAPIHandler();

  const [loading, setLoading] = useState(true);
  const pollingRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [dataStreamingInfo, setDataStreamingInfo] = useState<TreauAPI.ResponsesAdminGetDataStreamingShadowResponseData>({
    desiredDataStreaming: TreauAPI.ResponsesAdminGetDataStreamingShadowResponseDataReportedDataStreamingEnum.Off,
    reportedDataStreaming: TreauAPI.ResponsesAdminGetDataStreamingShadowResponseDataReportedDataStreamingEnum.Off,
  });
  const [notify, setNotify] = useState(true);
  const [desiredMatchesReported, setDesiredMatchesReported] = useState(true);

  const fetchDataStreamingInfo = () => {
    setLoading(true);
    apiHandler.handleRequest(
      treauApiClient.adminsAPI.adminGetDataStreamingShadow(props.unid),
      (response: AxiosResponse<TreauAPI.ResponsesAdminGetDataStreamingShadowResponse>) => {
        setDataStreamingInfo(response.data.data);
        const desired = response.data.data.desiredDataStreaming as string;
        const reported = response.data.data.reportedDataStreaming as string;
        if (desired !== reported) {
          pollingRef.current = setTimeout(fetchDataStreamingInfo, REFRESH_INTERVAL_SECONDS * 1000);
        }
      },
      undefined,
      () => setLoading(false)
    );
  };

  const turnDataStreamingOff = () => {
    setLoading(true);
    const input = { dataStreaming: TreauAPI.AdminUpdateDataStreamingShadowInputDataStreamingEnum.Off } as TreauAPI.AdminUpdateDataStreamingShadowInput;


    apiHandler.handleRequest(
      treauApiClient.adminsAPI.adminUpdateDataStreamingShadow(
        props.unid,
        input
      ),
      (response: AxiosResponse<TreauAPI.ResponsesAPIResponse>) => fetchDataStreamingInfo(),
      () => setLoading(false),
      undefined
    );
  };

  const turnDataStreamingOn = () => {
    setLoading(true);
    const input = {
      dataStreaming: TreauAPI.AdminUpdateDataStreamingShadowInputDataStreamingEnum.On,
      notify,
    } as TreauAPI.AdminUpdateDataStreamingShadowInput;


    apiHandler.handleRequest(
      treauApiClient.adminsAPI.adminUpdateDataStreamingShadow(
        props.unid,
        input
      ),
      (response: AxiosResponse<TreauAPI.ResponsesAPIResponse>) => fetchDataStreamingInfo(),
      () => setLoading(false),
      undefined
    );
  };


  useEffect(
    () => {
      fetchDataStreamingInfo();
      return () => {
        if (pollingRef.current) {
          clearTimeout(pollingRef.current);
        }
      };
    },
    // eslint-disable-next-line
    []
  );
  useEffect(
    () => setDesiredMatchesReported(
      (dataStreamingInfo.desiredDataStreaming as string) === (dataStreamingInfo.reportedDataStreaming as string)
    ),
    [dataStreamingInfo]
  );

  if (loading) {
    return (
      <div className="data-streaming-toggle d-flex flex-column align-items-center p-5">
        <LoadingSpinner />
      </div>
    );
  } else if (desiredMatchesReported) {
    if (dataStreamingInfo.reportedDataStreaming === TreauAPI.ResponsesAdminGetDataStreamingShadowResponseDataReportedDataStreamingEnum.Off) {
      return (
        <div className="data-streaming-toggle d-flex flex-column align-items-center p-5">
          <p>Data Streaming: OFF.</p>
          <p className="mt-3">
            <Form.Check
              type="checkbox"
              label="Notify me if streaming is left on for an extended period of time"
              checked={notify}
              onChange={(e) => {
                if (e.target.checked) {
                  setNotify(true);
                } else {
                  setNotify(false);
                }
              }} />
          </p>
          <Button className="mt-3 text-uppercase" onClick={turnDataStreamingOn}>Turn On</Button>
        </div>
      );
    } else {
      return (
        <div className="data-streaming-toggle d-flex flex-column align-items-center p-5">
          <p>Data Streaming: ON.</p>
          <Button className="mt-3 text-uppercase" onClick={turnDataStreamingOff}>Turn Off</Button>
        </div>
      );
    }
  } else {
    return (
      <div className="data-streaming-toggle d-flex flex-column align-items-center p-5">
        <p className="text-center">Data streaming has been turned {dataStreamingInfo.desiredDataStreaming} but the device has not yet acknowledged the command.</p>
        <p className="text-center mt-3">This display will refresh every {REFRESH_INTERVAL_SECONDS} seconds.</p>
      </div>
    );
  }
};

export default DataStreamingForm;
