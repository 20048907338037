import Sidebar from "../Sidebar";

import './index.scss';

import avatar from '../../images/app-icon.png';
import { useEffect, useState } from "react";

import moment from 'moment';

interface ILayoutProps{
    children: JSX.Element;
}

const Layout = (props: ILayoutProps)=>{

    const [currentDate, setCurrentDate] = useState('');
    const [currentMonth, setCurentMonth] = useState('');
    const [currentTime, setCurrentTime] = useState({
        minutes: '',
        time :''
    });
    const date = moment().get('date');
    const month = moment().get('month');
    
    useEffect(()=>{
        setCurrentDate(moment().format('DD , yyyy'));
    },[date]);

    useEffect(()=>{
        setCurentMonth(moment().format('MMMM'));
    },[month]);


    useEffect(()=>{
        let to = setInterval(()=>{
            if(moment().format('mm') !== currentTime.minutes){
                setCurrentTime({
                    time : moment().format("hh:mma"),
                    minutes: moment().format('mm')
                });
            }
        },1000);

        return ()=>{
            clearInterval(to);
        }
    // eslint-disable-next-line
    },[]);
    
    return (
        <div className="layout">
            <Sidebar></Sidebar>
            <div className="account-information">
                <div className="avatar">
                    <img src={avatar} alt="" className="" />
                </div>
                <div className="date"><b>{currentMonth}</b> {currentDate}</div>
                <div className="dots"></div>
                <div className="time">{currentTime.time}</div>
            </div>
            <div className="layout-content">
                {props.children}
            </div>
        </div>
    )
}
export default Layout;
