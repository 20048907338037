import { convertCentigradeToFahrenheit } from '../../../../utils/utils';
import './index.scss';

interface IDvComponentProps{
    heatTargetTempC: number,
    coolTargetTempC:number
}

const DvComponent = (props: IDvComponentProps)=>{

    const {heatTargetTempC, coolTargetTempC }= props;

    return (
        <div className="dv-setpoint">
            <div className="temp">
                <p className=""><b>Heat Setpoint</b></p>
                <div className="temp-value">{
                    convertCentigradeToFahrenheit(heatTargetTempC)
                }
                <span className="">°F</span>
                </div>
            </div>
            <div className="temp">
                <p className=""><b>Cool Setpoint</b></p>
                <div className="temp-value">{
                    convertCentigradeToFahrenheit(coolTargetTempC)
                }  <span className="">°F</span></div></div>
        </div>
    )
}

export default DvComponent;
