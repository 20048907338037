import './index.scss';

interface IDvComponentProps{
    macId:string
}

const DvComponent = (props: IDvComponentProps)=>{

    const { macId } =props;
    
    return (
        <div className="dv-sn">
            <b>Mac Id:</b>{macId}
        </div>
    )
}

export default DvComponent;
