import React from 'react';
import './index.scss';

const LoadingSpinner = () => (
  <div className='d-flex justify-content-center align-items-center flex-column'>
    <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    <h4 className="text-rose text-center mt-3">loading...</h4>
  </div>
);
export default LoadingSpinner;
