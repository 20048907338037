import moment from 'moment';
import React, {createContext, useReducer} from 'react';
import { IFilter } from '../../@types/types';

interface DownloadContextState {
    filter: IFilter;
}
const initialState:DownloadContextState = {
    filter: {
        indoor:'',
        outdoor:'',
        startDate:moment().format('MM/DD/YYYY'),
        endDate:moment().format('MM/DD/YYYY'),
        startTime:'0:00AM',
        endTime:'0:00AM',
    },
};

export interface DownloadContextAction {
    type: string;
    filter?: IFilter;
}

type DownloadAction = string;

export const SET_DOWNLOAD_FILTER_ACTION:DownloadAction = "SET_DOWNLOAD_FILTER_ACTION";

const reducer = (state:DownloadContextState, action:DownloadContextAction) => {
    switch (action.type) {
        case SET_DOWNLOAD_FILTER_ACTION:
            let newFilter = {...state.filter};
            if(action.filter){
                newFilter = action.filter
            }
            return {
                ...state,
                filter: newFilter,
            }
        default:
            return state;
    }
};
interface DownloadContextType{
    state: DownloadContextState,
    dispatch: React.Dispatch<DownloadContextAction>
}

export const DownloadContext = createContext<DownloadContextType>({state:initialState,dispatch: () => null});
export const DownloadContextProvider = (props:any) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <DownloadContext.Provider value={{state, dispatch}}>
            {props.children}
        </DownloadContext.Provider>
    );
};