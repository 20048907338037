import moment from 'moment';
import React, {createContext, useReducer} from 'react';
import { IFilter } from '../../@types/types';

export interface IError{
    errorCode:string;
    errorMessage:string;
}

interface ErrorContextState {
    errors : IError[],
    filter: IFilter;
}
const initialState:ErrorContextState = {
    errors:[],
    filter: {
        indoor:'',
        outdoor:'',
        startDate:moment().format('MM/DD/YYYY'),
        endDate:moment().format('MM/DD/YYYY'),
        startTime:'0:00AM',
        endTime:'0:00AM',
    },
};

export interface ErrorContextAction {
    type: string;
    errors?:IError[],
    filter?: IFilter;
}

type ErrorAction = string;

export const SET_ERROR_FILTER_ACTION:ErrorAction = "SET_ERROR_FILTER_ACTION";
export const SET_ERRORS_ACTION:ErrorAction = "SET_ERRORS_ACTION";

const reducer = (state:ErrorContextState, action:ErrorContextAction) => {
    switch (action.type) {
        case SET_ERROR_FILTER_ACTION:
            let newFilter = {...state.filter};
            if(action.filter){
                newFilter = action.filter
            }
            return {
                ...state,
                filter: newFilter,
            }
        case SET_ERRORS_ACTION:
            let newErrors = state.errors;
            if(action.errors){
                newErrors = action.errors;
            }
            return {
                ...state,
                errors: newErrors,
            }
        default:
            return state;
    }
};
interface ErrorContextType{
    state: ErrorContextState,
    dispatch: React.Dispatch<ErrorContextAction>
}

export const ErrorContext = createContext<ErrorContextType>({state:initialState,dispatch: () => null});
export const ErrorContextProvider = (props:any) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <ErrorContext.Provider value={{state, dispatch}}>
            {props.children}
        </ErrorContext.Provider>
    );
};