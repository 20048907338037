import { useHistory } from 'react-router-dom';
import { TreauAPI } from '../../api_clients/TreauApiClient';
import LoadingSpinner from '../LoadingSpinner';
import product from '../../images/product.png';
import {
  getRunStatusName,
  getRunStatusColor,
  getSystemStateName,
  getSystemStateColor,
  getPowerName,
  getModeName,
  getFanSpeedName,
  convertCentigradeToFahrenheit
} from '../../utils/utils';
import './index.scss';

interface DeviceListProps {
  devices: TreauAPI.ResponsesAdminDeviceOverview[];
  loading?: boolean;
}


const DeviceList = (props: DeviceListProps) => {

  const { devices } = props;

  const history = useHistory();

  const onListviewItemClick = (dv: TreauAPI.ResponsesAdminDeviceOverview) => {
    history.push('/dashboard/' + dv.unid)

  }
  if (props.loading) {
    return (
      <>
        <table className="table listview-table">
          <thead className='listview-header'>
            <tr>
              <th scope="col"></th>
              <th scope="col">State</th>
              <th scope="col">Power</th>
              <th scope="col">Mode</th>
              <th scope="col">Fan Speed</th>
              <th scope="col">Indoor Temp</th>
              <th scope="col">Set Point</th>
              <th scope="col">Thing Name</th>
              <th scope="col">Last Shadow Timestamp</th>
            </tr>
          </thead>
        </table>
        <div className='d-flex justify-content-center align-items-center'>
          <LoadingSpinner />
        </div>
      </>
    );
  } else if (devices && devices.length > 0) {
    return (
      <table className="table listview-table">
        <thead className='listview-header'>
          <tr>
            <th scope="col"></th>
            <th scope="col">State</th>
            <th scope="col">Power</th>
            <th scope="col">Mode</th>
            <th scope="col">Fan Speed</th>
            <th scope="col">Indoor Temp</th>
            <th scope="col">Set Point 1</th>
            <th scope="col">Set Point 2</th>
            <th scope="col">Thing Name</th>
            <th scope="col">Last Shadow Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {devices.map(device => {
            const shadowDoc = device.shadowDoc || {};
            return (
              <tr onClick={() => onListviewItemClick(device)} key={device.unid} className='listview-tr'>
                <th scope="row">
                  <div className="product-image">
                    <img src={product} alt="" />
                  </div>
                </th>
                <td>
                  <div className="mode">
                    <div className="dots" style={{ backgroundColor: shadowDoc['run_status'] !== undefined ? getRunStatusColor(shadowDoc['run_status']) : getSystemStateColor(shadowDoc['state_system']) }} ></div>
                    <p className="">{shadowDoc['run_status'] !== undefined ? getRunStatusName(shadowDoc['run_status']) : getSystemStateName(shadowDoc['state_system'])}</p>
                  </div>
                </td>
                <td>
                  <div className="mode">
                    <p className="">{getPowerName(shadowDoc['state_power'] || shadowDoc['power_state'])}</p>
                  </div>
                </td>
                <td>
                  <div className="mode">
                    <p className="">{getModeName(device.deviceType, shadowDoc['op_mode'])}</p>
                  </div>
                </td>
                <td>
                  <div>{getFanSpeedName(shadowDoc['power_level'] || shadowDoc['fan_setting'])}</div>
                </td>
                <td>
                  <div>{convertCentigradeToFahrenheit(shadowDoc['temp_atei_c'] || shadowDoc['indoor_temp_c'])}<span>°F</span></div>
                </td>
                <td>
                  <div>{convertCentigradeToFahrenheit(shadowDoc['target_temp_c'] || shadowDoc['heat_target_temp_c'])}<span>°F</span></div>
                </td>
                <td>
                  {
                    shadowDoc['cool_target_temp_c'] !== undefined && <div>{convertCentigradeToFahrenheit(shadowDoc['cool_target_temp_c'])}<span>°F</span></div>
                  }
                </td>
                <td>
                  <div>{device.thingName}</div>
                </td>
                <td>
                  <div>{device.lastConnectedTime}</div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    );
  } else {
    return (<div className="listview-no-device">No Content</div>);
  }
}

export default DeviceList;
