import {getRunStatusName, getSystemStateName, getPowerName, getModeName, getFanSpeedName } from '../../../../utils/utils';
import './index.scss';

interface IDvComponentProps {
    deviceType: string|null
    state: number
    power: number
    mode: number
    fanSpeed: number
    runStatus: number
}

const DvComponent = (props: IDvComponentProps) => {

    const { deviceType, state, power, mode, fanSpeed, runStatus } = props;

    return (
        <>
            <div className="dv-mode">
                <div className="power-name">{runStatus !== undefined ? getRunStatusName(runStatus) : getSystemStateName(state)}</div>
            </div>
            <div className="dv-mode">
              <div className="mode-name"><div>Power:</div><div>{getPowerName(power)}</div></div>
              <div className="mode-name"><div>Mode:</div><div>{getModeName(deviceType, mode)}</div></div>
              <div className="mode-name"><div>Fan Speed:</div><div>{getFanSpeedName(fanSpeed)}</div></div>
            </div>
        </>
    )
}

export default DvComponent;
