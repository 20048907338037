import { useEffect, useState } from 'react';

import Layout from "../../components/Layout";
import DeviceGallery from '../../components/DeviceGallery';
import DeviceList from '../../components/DeviceList';
import Loading from '../../components/Modal/Loading';

import { AxiosResponse } from 'axios';

import { useToastAPIHandler } from '../../hooks/api';
import treauApiClient, { TreauAPI } from '../../api_clients/TreauApiClient';

import './index.scss';


const NychaPage = () => {

  const apiHandler = useToastAPIHandler();
  const [tableView, setTableView] = useState<'gallery' | 'list' >('list');
  const [isLoading, setLoading] = useState(true);
  const [devices, setDevices] = useState<TreauAPI.ResponsesAdminDeviceOverview[]>([]);

  useEffect(() => {
    apiHandler.handleRequest(
      treauApiClient.adminsAPI.adminGetNychaDevices(),
      (response: AxiosResponse<TreauAPI.ResponsesAdminDevicesResponse>) => {
        setDevices(response.data.data.devices);
      },
      undefined,
      () => setLoading(false)
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div className="nycha-page">
      <Loading isLoading={isLoading} />
      <Layout>
        <div className="nycha-page-content">
          <div className="title">NYCHA Units (view the dashboard in <a href="https://018e1361.us1a.app.preset.io/superset/dashboard/12" target="_blank" rel="noreferrer">Preset</a>)</div>
            <div className="nycha-page-result">
              <div className="nycha-page-result-header">
                  <div className="nycha-page-result-header-view">
                      <div className={"item " + (tableView === 'gallery' ? 'item-active' : '')} onClick={() => setTableView('gallery')} >Gallery</div>
                      <div className={"item " + (tableView === 'list' ? 'item-active' : '')} onClick={() => setTableView('list')}>List</div>
                      <div className="dots"></div>
                  </div>
              </div>
              <div className="nycha-page-result-content">
                  <div className="divider"></div>
                  {tableView === 'gallery' ? <DeviceGallery devices={devices} /> : <DeviceList devices={devices} />}
              </div>
          </div>
        </div>
      </Layout>
    </div>
  );

}
export default NychaPage;
