import { convertCentigradeToFahrenheit } from '../../../../utils/utils';
import deviceImage from '../../../../images/productx3.png';
import './index.scss';

interface IDvComponentProps{
    temp: number
}

const DvComponent = (props: IDvComponentProps)=>{
    const {temp }= props;
    return (
        <div className="dv-img">
            <img src={deviceImage} alt="" className="" />
            {temp && <div className="temp">{convertCentigradeToFahrenheit(temp)} <span className="">°F</span></div>}
        </div>
    )
}

export default DvComponent;
