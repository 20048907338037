import React, {createContext, useReducer} from 'react';

interface EmailContextState {
    email:string|undefined;
}
const initialState:EmailContextState = {
    email: undefined,
};

export interface EmailContextAction {
    type: string;
    email?: string;
}

type EmailAction = string;
export const SET_EMAIL_ACTION:EmailAction = "SET_EMAIL";

const reducer = (state:EmailContextState, action:EmailContextAction) => {
    switch (action.type) {
        case SET_EMAIL_ACTION:
            let newEmail = action.email;
            return {email: newEmail}
        default:
            return state;
    }
};
interface EmailContextType{
    state: EmailContextState,
    dispatch: React.Dispatch<EmailContextAction>
}

export const EmailContext = createContext<EmailContextType>({state:initialState,dispatch: () => null});
export const EmailContextProvider = (props:any) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <EmailContext.Provider value={{state, dispatch}}>
            {props.children}
        </EmailContext.Provider>
    );
};