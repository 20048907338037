import { Configuration } from "@bohe-in/treau-api-client-ts";

const axiosConfig = new Configuration({
  baseOptions: {
    withCredentials: true,
  }
});

let local = {
    treauApi:{
        endpoint: "http://localhost:5001"
    },
    axiosConfig
};

let dev = {
    treauApi:{
      endpoint: "https://api.cloud-dev.gradientcomfort.com"
    },
    axiosConfig,
};

let prod = {
    treauApi:{
        endpoint: "https://api.cloud.gradientcomfort.com"
    },
    axiosConfig,
};

const config  = () =>{
    console.log(process.env.REACT_APP_ENV);
    return process.env.REACT_APP_ENV === "prod"?prod:process.env.REACT_APP_ENV === "dev"?dev:local;
};

export default config();
