import { convertCentigradeToFahrenheit } from '../../../../utils/utils';
import './index.scss';

interface IDvComponentProps{
    insideTemprature: number,
    outsideTemprature:number
}

const DvComponent = (props: IDvComponentProps)=>{

    const {insideTemprature, outsideTemprature }= props;

    return (
        <div className="dv-temprature">
            <div className="temp">
                <p className=""><b>Inside</b></p>
                <div className="temp-value">{
                    convertCentigradeToFahrenheit(insideTemprature)
                }
                <span className="">°F</span>
                </div>
            </div>
            <div className="temp">
                <p className=""><b>Outside</b></p>
                <div className="temp-value">{
                    convertCentigradeToFahrenheit(outsideTemprature)
                }  <span className="">°F</span></div></div>
        </div>
    )
}

export default DvComponent;