import './index.scss';

interface IDvComponentProps{
    thingName:string
}

const DvComponent = (props: IDvComponentProps)=>{

    const { thingName } =props;

    return (
        <div className="dv-thing-name">
            <b>Thing Name:</b>{thingName}
        </div>
    )
}

export default DvComponent;
