import moment from "moment";
import { toast } from 'react-toastify';


export function intToBinary(number: number, res = ""):string {
    if (number < 1){
        if (res === "") return "0"
        return res
    }
    return intToBinary(Math.floor(number / 2), number % 2 + res)
}

export function isNullOrUndefined(value:any){
    return value === null || value === undefined;
}

export const getDaysBetweenDates = (startDate: string, endDate: string) => {
    const now = moment.utc(startDate);
    const dates = [];
    while (now.isSameOrBefore(moment.utc(endDate))){
      dates.push(now.format('YYYY-MM-DD'));
      now.add(1, 'days');
    }
    return dates;
};

const system_state_units = [
    {   
        index: 0,
        name: 'Service Required',
        color:'#AFC3BC'
    },
    {
        index: 1,
        name: 'Initialize',
        color:'#AFC3BC'
    },
    {
        index: 2,
        name: 'Heat',
        color:'#FE855B'
    },
    {
        index: 3,
        name: 'Cool',
        color:'#4BD4FF'
    },
    {
        index: 4,
        name: 'Alarm',
        color:'#AFC3BC'
    },
    {
        index: 5,
        name: 'Standby',
        color:'#AFC3BC'
    },
    {
        index: 6,
        name: 'Diagnostic',
        color:'#AFC3BC'
    },
    {
        index: 7,
        name: 'ODB Power Off',
        color:'#AFC3BC'
    },
    {
        index: 8,
        name: 'Fan Only',
        color:'var(--gray-color)',
    }
]

const runStatusUnits = [
    {
        index: 0,
        name: 'Standby',
        color:'#AFC3BC'
    },
    {
        index: 1,
        name: 'Idle',
        color:'#AFC3BC'
    },
    {
        index: 2,
        name: 'Cool',
        color:'#4BD4FF'
    },
    {
        index: 3,
        name: 'Heat',
        color:'#FE855B'
    },
    {
        index: 4,
        name: 'Preparing',
        color:'#AFC3BC'
    }
]



const power_state_units = [
    {   
        index: 0,
        name: 'Off',
    },
    {
        index: 1,
        name: 'On',
    },
    {
        index: 2,
        name: 'Offline',
    },
    {
        index: 3,
        name: 'Updating',
    }
]

const mode_units = [
    {
        index: 0,
        name: 'Cool',
        color:'#4BD4FF'
    },
    {
        index: 1,
        name: 'Heat',
        color:'#FE855B'
    },
    {
        index: 2,
        name: 'Heat & Cool',
        color:'var(--blue-color)',
        oldName: 'Smart'
    },
    {
        index: 3,
        name: 'Fan Only',
        color:'var(--gray-color)'
    },
    {
        index: 4,
        name: 'Dry',
        color:'',
        oldName: 'None'
    },
    {
        index: 5,
        name: 'None',
        color:'',
    }
]

const fanSpeedUnits = [
  {
    index: 0,
    name: 'Auto',
  },
  {
    index: 1,
    name: 'High',
  },
  {
    index: 2,
    name: 'Medium',
  },
  {
    index: 3,
    name: 'Low',
  },
  {
    index: 4,
    name: 'Silent',
  },
  {
    index: 5,
    name: 'Boost',
  }
];

export const getSystemStateName=(systemState:number)=>{
    let name = '';
    system_state_units.forEach(system_state_unit => {
        if(system_state_unit.index === systemState){
            name = system_state_unit.name
        }
    });
    return name;
}
export const getSystemStateColor=(systemState:number)=>{
    let color = '';
    system_state_units.forEach(system_state_unit => {
        if(system_state_unit.index === systemState){
            color = system_state_unit.color
        }
    });
    return color;
}

export const getRunStatusName=(runStatus:number)=>{
    let name = '';
    runStatusUnits.forEach(runStatusUnit => {
        if(runStatusUnit.index === runStatus){
            name = runStatusUnit.name
        }
    });
    return name;
}
export const getRunStatusColor=(runStatus:number)=>{
    let color = '';
    runStatusUnits.forEach(runStatusUnit => {
        if(runStatusUnit.index === runStatus){
            color = runStatusUnit.color
        }
    });
    return color;
}


export const getAllSystemStateName=()=>{
    let name: string[] = [];
    system_state_units.forEach(system_state_unit => {
        name.push(system_state_unit.name)
    });
    return name;
}

export const getPowerName=(powerState:number)=>{
    let name = '';
    // console.log(powerState);
    power_state_units.forEach(power_state_unit => {
        if(power_state_unit.index === powerState){
            name = power_state_unit.name
        }
    });
    return name;
}

export const getAllPowerStateName=()=>{
    let name: string[] = [];
    power_state_units.forEach(power_state_unit => {
        name.push(power_state_unit.name)
    });
    return name;
}

export const getModeColor=(mode:number)=>{
    let color = '';
    mode_units.forEach(unit => {
        if(unit.index === mode){
            color = unit.color
        }
    });
    return color;
}

export const getModeName=(deviceType: string|null, mode:number)=>{
    let name = '';
    mode_units.forEach(unit => {
        if(unit.index === mode){
          if (unit.oldName && deviceType !== 'denali') {
            name = unit.oldName
          } else {
            name = unit.name
          }
        }
    });
    return name;
}

export const getFanSpeedName = (fanSpeed: number) => {
  let name = 'Off';
  fanSpeedUnits.forEach(unit => {
    if (unit.index === fanSpeed) {
      name = unit.name;
    }
  });
  return name;
}

export const getAllModeName=()=>{
    let name: string[] = [];
    mode_units.forEach(mode_unit => {
        name.push(mode_unit.name)
    });
    return name;
}


export const convertCentigradeToFahrenheit = (celsius: number)=>{
    if(celsius !== null){
        var cToFahr = celsius * 9 / 5 + 32;
        return Number(cToFahr).toFixed(1)
    }else{
        return 0
    }
}

export function validateEmail(email:string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
export const showError = (error:any)=>{
    console.log(error.request.response);
    if(!isNullOrUndefined(error.request) && !isNullOrUndefined(error.request.response)){
        let errorResponse;
        try{
            errorResponse = JSON.parse(error.request.response);
        }catch (e) {
            console.log(e)
        }
        if(errorResponse && !isNullOrUndefined(errorResponse.validationErrors)){
            Object.values<string>(errorResponse.validationErrors).forEach((errorMessage)=>{
                toast.error(errorMessage, {position: toast.POSITION.TOP_CENTER});
            })
        }else if(errorResponse && errorResponse.message){
            toast.error(errorResponse.message, {position: toast.POSITION.TOP_CENTER});
        }else{
            toast.error("Unknown Error", {position: toast.POSITION.TOP_CENTER});
        }
    }else{
        toast.error("Unknown Error", {position: toast.POSITION.TOP_CENTER});
    }
}
