import React from 'react';
import pagiIcon from '../../images/pagination.png';
import './index.scss';

interface IProps {
  currentPage: number;
  pageSize: number;
  onPageChange: (pageNumber:number)=>void;
  totalCount: number;
  disabled?: boolean;
}

export default function Pagination(props: IProps){
  const { currentPage, disabled, pageSize, onPageChange, totalCount } = props;
  const totalPages = Math.ceil(totalCount / pageSize);

  const handleClickPrevious = () => {
    if (disabled || currentPage === 1) {
      return;
    }
    onPageChange(currentPage - 1);
  }

  const handleClickNext = () => {
    if (disabled || currentPage === totalPages) {
      return;
    }
    onPageChange(currentPage + 1);
  }

  const handlePageNumberClick = (page: number) => {
    if (disabled) {
      return;
    }
    onPageChange(page);
  }

  const renderPagiItem =(page: number)=>{
    return (
      <p key={page.toString()} onClick={() => handlePageNumberClick(page)}
        className={`pagination__tab ${page === currentPage ? 'pagination__tab-current' : ''} ${props.disabled ? 'pagination__button-disable' : ''}`}>
        {page}
      </p>
    )
  }

  const renderPagination = ()=>{
    const result = []
    if (totalPages > 5 + 2) {
      if (currentPage < 5) {
        for (let i = 0; i < totalPages; i ++) {
          if(i < 5){
            result.push(renderPagiItem(i+1));
          }
        }

        result.push(<p className={`pagination__tab `}>{"..."}</p>);

        for (let i = totalPages - 2; i< totalPages; i ++) {
          result.push(renderPagiItem(i+1));
        }
      } else {
        for (let i = 0; i < 2; i ++) {
          result.push(renderPagiItem(i+1));
        }

        if (currentPage > 5) {
          result.push(<p className={`pagination__tab `}>{"..."}</p>);
        }

        for (let i = currentPage - 3 ; i < currentPage + 2; i ++) {
          if(i < totalPages - 1){
            result.push(renderPagiItem(i+1));
          }
        }

        if (currentPage < totalPages - 3) {
          result.push(<p className={`pagination__tab `}>{"..."}</p>);
        }

        for (let i = totalPages - (totalPages - 5 > currentPage ? 2:  1) ; i < totalPages; i ++) {
          result.push(renderPagiItem(i+1));
        }
      }
    } else {
      for (let i = 0; i < totalPages; i ++) {
        result.push(renderPagiItem(i+1))
      }
    }
    return result;
  }

  if (totalCount === 0 || totalPages < 2) {
    return null;
  }

  return (
    <div className="pagination__container">
      <div className="pagination__content">
        <p onClick={handleClickPrevious} className={`previous ${(Math.ceil(currentPage) === 1 || disabled) && 'pagination__button-disable'}`}>
          <img src={pagiIcon} alt="" className=""/>
        </p>
        {renderPagination()}
        <p onClick={handleClickNext} className={`next ${(Math.ceil(currentPage) === Math.ceil(totalPages) || disabled) && 'pagination__button-disable'}`}>
          <img src={pagiIcon} alt="" className=""/>
        </p>
      </div>
    </div>
  );
}
