import './index.scss';

interface IDvComponentProps{
    lastConnected:string
}

const DvComponent = (props: IDvComponentProps)=>{

    const { lastConnected } =props;

    return (
        <div className="dv-last-connect">
            <b>Last Shadow Timestamp:</b>{lastConnected}
        </div>
    )
}

export default DvComponent;
