import React, {useContext} from 'react'
import {RouteProps, Route} from 'react-router-dom'
import {
  LoginContext,
  loginContextHasRole,
} from "../../contexts/LoginContext";

type RoleRouteProps = RouteProps & { roles: string[] };

export const UserRestrictedRoute = (routeProps:RouteProps) =>{

  const loginContext = useContext(LoginContext);

  if (loginContext.state.isLoggedIn) {
    return <Route {...routeProps} />;
  } else {
    window.location.href = "/";
    return null;
  }
};


export const RoleRestrictedRoute = (roleRouteProps:RoleRouteProps) =>{

  const loginContext = useContext(LoginContext);

  const {roles, ...routeProps} = roleRouteProps;

  if (loginContext.state.isLoggedIn && roles.some(role => loginContextHasRole(loginContext, role))){
    return <Route {...routeProps} />;
  } else {
    window.location.href = "/";
    return null;
  }
};

