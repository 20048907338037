import * as treauAPI from "@bohe-in/treau-api-client-ts";
import config from "../config/config";

export class TreauApiClient {
    adminsAPI: treauAPI.AdminsApi;
    devicesAPI: treauAPI.DevicesApi;
    usersAPI: treauAPI.UsersApi;
    optionsAPI: treauAPI.OptionsApi;
    constructor() {
        this.adminsAPI = new treauAPI.AdminsApi(config.axiosConfig,config.treauApi.endpoint);
        this.devicesAPI = new treauAPI.DevicesApi(config.axiosConfig,config.treauApi.endpoint);
        this.usersAPI = new treauAPI.UsersApi(config.axiosConfig,config.treauApi.endpoint);
        this.optionsAPI = new treauAPI.OptionsApi(config.axiosConfig,config.treauApi.endpoint);
    }
}
const treauApiClient = new TreauApiClient();

export default treauApiClient;
export {treauAPI as TreauAPI};
