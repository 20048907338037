import React, {createContext, useReducer} from 'react';

interface LoginContextState {
    roles:Array<string>|undefined;
    isLoggedIn: boolean;
}

export const FirmwareManagerRole = 'FirmwareManager';
export const ProductManagerRole = 'ProductManager';
export const AdministratorRole = 'Administrator';

const initialState:LoginContextState = {
    roles: undefined,
    isLoggedIn:false
};

export interface LoginContextAction {
    type: string;
    roles?: Array<string>;
}

export const EMAIL_ITEM_KEY = "__TREAU_EMAIL__"

type TokenAction = string;
export const LOGIN_ACTION:TokenAction = "LOGIN";
export const LOGOUT_ACTION:TokenAction = "LOGOUT";

const reducer = (state:LoginContextState, action:LoginContextAction) => {
    switch (action.type) {
        case LOGIN_ACTION:
            return {
                isLoggedIn: true,
                roles: action.roles,
            };
        case LOGOUT_ACTION:
            if(localStorage) {
                localStorage.removeItem(EMAIL_ITEM_KEY);
            }
            return {
                isLoggedIn: false,
                roles: undefined,
            };
        default:
            return state;
    }
};
interface LoginContextType{
    state: LoginContextState,
    dispatch: React.Dispatch<LoginContextAction>
}

export const LoginContext = createContext<LoginContextType>({state:initialState,dispatch: () => null});
export const LoginContextProvider = (props:any) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <LoginContext.Provider value={{state, dispatch}}>
            {props.children}
        </LoginContext.Provider>
    );
};

export const loginContextHasRole = (loginContext: LoginContextType, role: string): boolean => (
  loginContext.state.isLoggedIn &&
  loginContext.state.roles &&
  (loginContext.state.roles.indexOf(role) !== -1 ||
    loginContext.state.roles.indexOf(AdministratorRole) !== -1)
)
