import React, {createContext, useReducer} from 'react';

interface LocationContextState {
    locations: string[]
}
const initialState:LocationContextState = {
    locations:[]
};

export interface LocationContextAction {
    locations: string[];
    type:string
}

type LocationAction = string;
export const GET_LOCATION:LocationAction = "GET_LOCATION";

const reducer = (state:LocationContextState, action:LocationContextAction) => {
    switch (action.type) {
        case GET_LOCATION:
            let newLocations = state.locations;
            if(action.locations){
                newLocations = action.locations
            }
            return {
                locations: newLocations,
            };
        
        default:
            return state;
    }
};
interface LocationContextType{
    state: LocationContextState,
    dispatch: React.Dispatch<LocationContextAction>
}

export const LocationContext = createContext<LocationContextType>({state:initialState,dispatch: () => null});
export const LocationContextProvider = (props:any) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <LocationContext.Provider value={{state, dispatch}}>
            {props.children}
        </LocationContext.Provider>
    );
};

