import { ChangeEvent, useEffect, useRef, useState } from 'react';

import Layout from "../../components/Layout"
import DeviceGallery from '../../components/DeviceGallery';
import Pagination from '../../components/Pagination';
import DeviceList from '../../components/DeviceList';

import { AxiosResponse } from 'axios';

import { useToastAPIHandler } from '../../hooks/api';
import treauApiClient, { TreauAPI } from '../../api_clients/TreauApiClient';

import './index.scss';

const PAGE_SIZE = 10;

interface DeviceFetchConfig {
  currentPage: number;
  searchType?: TreauAPI.RequestsDeviceSearchSearchTypeEnum;
  searchQuery?: string
}

interface Devices {
  page: TreauAPI.ResponsesAdminDeviceOverview[],
  count: number,
  loading: boolean,
}


const SearchPage = () => {

  const apiHandler = useToastAPIHandler();
  const typingRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [tableView, setTableView] = useState<'gallery' | 'list' >('gallery');
  const [devices, setDevices] = useState<Devices>({page: [], count: 0, loading: true});
  const [idbMacId, setIdbMacId] = useState<string>('');
  const [ownerName, setOwnerName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [thingName, setThingName] = useState<string>('');
  const [fetchConfig, setFetchConfig] = useState<DeviceFetchConfig>({currentPage: 1});

  const clearAllFields = () => {
      idbMacId && setIdbMacId('');
      ownerName && setOwnerName('');
      email && setEmail('');
      thingName && setThingName('');
  }


  const makeSearchFieldChangeCallback = (
    elementSearchType: TreauAPI.RequestsDeviceSearchSearchTypeEnum,
    valueSetter: (s: string) => void,
    valueTransform?: (s: string) => string,
  ): (e: ChangeEvent<HTMLInputElement>) => void => {
    return (e: ChangeEvent<HTMLInputElement>): void => {
      clearAllFields();
      const value = !!valueTransform ? valueTransform(e.target.value as string) : e.target.value as string;
      valueSetter(value);
      if (typingRef.current) {
        clearTimeout(typingRef.current);
      }
      (typingRef as React.MutableRefObject<ReturnType<typeof setTimeout> | null>).current = setTimeout(()=>{
        setDevices({...devices, count: 0}); // hide pagination when changing search
        if (value) {
          setFetchConfig({
            currentPage: 1,
            searchType: elementSearchType,
            searchQuery: value,
          });
        } else {
          setFetchConfig({currentPage: 1});
        }
      }, 500)
    }
  };

  useEffect(() => {
    setDevices({
      page: [],
      count: devices.count, //keep the rendered pages while loading next page
      loading: true,
    });
    const request = !!fetchConfig.searchType ?  treauApiClient.adminsAPI.adminSearchDevicesPaginated(
        fetchConfig.currentPage,
        fetchConfig.searchQuery,
        fetchConfig.searchType,
        PAGE_SIZE,
      ) : treauApiClient.adminsAPI.adminGetDevices(
        fetchConfig.currentPage,
        PAGE_SIZE
      );
    apiHandler.handleRequest(
      request,
      (response: AxiosResponse<TreauAPI.ResponsesAdminDevicesResponse>) => {
        setDevices({
          page: response.data.data.devices,
          count: response.data.data.count,
          loading: false,
        });
      },
      undefined,
      () => {}
    );
    // eslint-disable-next-line
  }, [fetchConfig]);

  return (
    <div className="search-page">
      <Layout >
        <div className="search-page-content">
          <div className="title">Search</div>
          <div className="filter">
            <div className="device-search">
                <div className="search-content">
                    <div className="group-input">
                        <div className="search-item">
                            <div className="text indoor-name">Idb Mac Id:</div>
                            <input
                              id='indoorSearch'
                              onChange={makeSearchFieldChangeCallback(
                                TreauAPI.RequestsDeviceSearchSearchTypeEnum.IdbMacId,
                                setIdbMacId,
                                (s) => s.toUpperCase()
                              )}
                              value={idbMacId}
                              className="input"
                              placeholder='00:11:22:aa:bb:cc'>
                            </input>
                        </div>
                        <div className="search-item">
                            <div className="text owner-name">Primary Owner Name:</div>
                            <input
                              id='ownerNameSearch'
                              onChange={makeSearchFieldChangeCallback(
                                TreauAPI.RequestsDeviceSearchSearchTypeEnum.Name,
                                setOwnerName,
                                (s) => s.toUpperCase()
                              )}
                              value={ownerName}
                              className="input"
                              placeholder='USER NAME'>
                            </input>
                        </div>
                        <div className="search-item">
                            <div className="text thing-name">Thing Name:</div>
                            <input
                              id='thingNameSearch'
                              onChange={makeSearchFieldChangeCallback(
                                TreauAPI.RequestsDeviceSearchSearchTypeEnum.ThingName,
                                setThingName,
                              )}
                              value={thingName}
                              className="input"
                              placeholder='THING NAME'>
                            </input>
                        </div>
                        <div className="search-item">
                            <div className="text email">Email:</div>
                            <input
                              id='emailSearch'
                              onChange={makeSearchFieldChangeCallback(
                                TreauAPI.RequestsDeviceSearchSearchTypeEnum.Email,
                                setEmail,
                              )}
                              value={email}
                              className="input"
                              placeholder='gradient@bohemian.cc'>
                            </input>
                        </div>
                    </div>
                </div>
            </div>
          </div>

        <div style={{ marginTop: '80px' }} className="search-page-result">
          <div className="search-page-result-header">
            <div className="title">Results</div>
            <div className="search-page-result-header-view">
              <div className={"item " + (tableView === 'gallery' ? 'item-active' : '')} onClick={() => setTableView('gallery')} >Gallery</div>
              <div className={"item " + (tableView === 'list' ? 'item-active' : '')} onClick={() => setTableView('list')}>List</div>
              <div className="dots"></div>
            </div>
          </div>
          <div className="search-page-result-content">
            <div className="divider"></div>
              {
                tableView === 'gallery' ?
                <DeviceGallery devices={devices.page} loading={devices.loading} /> :
                <DeviceList devices={devices.page} loading={devices.loading} />
              }
              <Pagination
                currentPage={fetchConfig.currentPage}
                pageSize={PAGE_SIZE}
                onPageChange={(page) => setFetchConfig({...fetchConfig, currentPage: page})}
                totalCount={devices.count}
                disabled={devices.loading}
              />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
export default SearchPage;
