import React  from 'react';
import {Button} from 'react-bootstrap';

import './index.scss';

interface ButtonProps{
  onClick?:() => void;
  className?:string;
  deleteButton?: boolean;
  style?:any;
  disabled?: boolean;
  children: React.ReactNode;
}

const GradientButton = (props: ButtonProps) => {
  let className = 'gradient-button';
  if (props.className) {
    className = className + ' ' + props.className;
  }
  if (props.disabled) {
    className = className + ' disabled';
  }
  if (props.deleteButton) {
    className += ' delete-button';
  }
  return (
    <Button
      className={className}
      onClick={() => props.onClick && !props.disabled && props.onClick()}
      style={props.style || {}}
    >
      {props.children}
    </Button>
  );
}

export default GradientButton;
