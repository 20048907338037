import {  LOGIN_ACTION, LOGOUT_ACTION, LoginContextAction} from "."

export const login = (roles: Array<string>, dispatch: React.Dispatch<LoginContextAction>)=>{
    dispatch({
        type: LOGIN_ACTION,
        roles,
    })
}

export const logout = (dispatch: React.Dispatch<LoginContextAction>)=>{
    dispatch({
        type: LOGOUT_ACTION,
    })
}
